import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['confirmButton', 'alertInfo', 'loadingSpinner'];

  connect() {
    this.confirmButtonTarget.classList.add('confirming-email__confirm-btn');

    setTimeout(() => {
      this.confirmButtonTarget.click();
    }, 6000);
  }
}
